import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SearchParameter } from 'src/app/shared/models/search-parameter.model';
import { firstValueFrom, forkJoin, Subject } from 'rxjs';
import { Tag } from '../models/tag.model';
import { Transaction, GetTransactionsResponse } from '../models/transaction.model';
import { TransactionsService } from './transactions.service';
import { GLTag } from '../models/glTag.model';

@Injectable({
	providedIn: 'root',
})
export class TransactionsBatchService {
	batchSize: number;
	batchProgress$: Subject<number>;
	private requestSize: number;
	private finishedRequests: number;
	private totalRequests: number;

	constructor(private transactionsService: TransactionsService) {
		this.requestSize = 1000;
		this.batchSize = 7;
		this.finishedRequests = 0;
		this.totalRequests = 0;
		this.batchProgress$ = new Subject<number>();
	}

	getBatchTransactions(
		q?: string,
		positionType?: string,
		params?: SearchParameter[],
		fullAccountNumbers: boolean = false,
		tags: Tag[] = [],
		glTags: GLTag[] = [],
		tqlJSONExpression?: Object
	): Promise<Transaction[]> {
		return new Promise(async (resolve, reject) => {
			this.batchProgress$.next(0);
			this.finishedRequests = 0;
			try {
				const totalTransactionsCount: number = await this.getTotalTransactionsCount(q, params, tqlJSONExpression);
				this.totalRequests = Math.ceil(totalTransactionsCount / this.requestSize);
				let transactionsList: Transaction[] = [];

				const requestPayloads: GetTransactionPayload[] = [];
				for (let i = 0; i < this.totalRequests; i++) {
					const sizeParam: number = this.requestSize * i;
					const payload: GetTransactionPayload = {
						tags: tags,
						glTags: glTags,
						q,
						positionType,
						from: sizeParam,
						params,
						size: this.requestSize,
						fullAccountNumbers,
						tqlJSONExpression,
					};
					requestPayloads.push(payload);
				}

				const numberOfBatchRequests: number = Math.ceil(this.totalRequests / this.batchSize);
				const batchRequestPayloads: GetTransactionPayload[][] = [];
				for (let j = 0; j < numberOfBatchRequests; j++) {
					batchRequestPayloads.push(requestPayloads.slice(j * this.batchSize, (j + 1) * this.batchSize));
				}
				for (let k = 0; k < batchRequestPayloads.length; k++) {
					const batchResult: Transaction[] = await this.batchTransactionsHelper(batchRequestPayloads[k]);
					transactionsList = transactionsList.concat(batchResult);
				}
				resolve(transactionsList);
			} catch (error) {
				reject(error);
			}
		});
	}

	private batchTransactionsHelper(payloads: GetTransactionPayload[]): Promise<Transaction[]> {
		return new Promise(async (resolve, reject) => {
			try {
				await forkJoin(
					payloads.filter((payload: GetTransactionPayload) => payload).map((filteredPayload: GetTransactionPayload) => this.getTransactions(filteredPayload))
				).subscribe(results => {
					const allTransactions: Transaction[] = Array.prototype.concat.apply([], results);
					resolve(allTransactions);
				});
			} catch (error) {
				reject(error);
			}
		});
	}

	getTransactions(payload: GetTransactionPayload): Promise<Transaction[]> {
		return new Promise(async (resolve, reject) => {
			try {
				if (payload) {
					const transactions: HttpResponse<GetTransactionsResponse> = await firstValueFrom(
						this.transactionsService.getTransactions(
							payload.tags,
							payload.glTags,
							payload.q,
							payload.positionType,
							payload.from,
							payload.params,
							payload.size,
							payload.fullAccountNumbers,
							null,
							payload.tqlJSONExpression
						)
					);
					this.finishedRequests += 1;
					this.batchProgress$.next((this.finishedRequests / this.totalRequests) * 100);
					resolve(transactions.body.transactions);
				} else {
					resolve([]);
				}
			} catch (error) {
				reject(error);
			}
		});
	}

	getTotalTransactionsCount(q?: string, params?: SearchParameter[], tqlJSONExpression?: Object): Promise<number> {
		return new Promise(async (resolve, reject) => {
			try {
				const totalTransactions: number = (
					await firstValueFrom(this.transactionsService.getTransactionsSummary(q, null, null, params, null, null, null, tqlJSONExpression))
				).body.totalTransactions;
				resolve(totalTransactions);
			} catch (error) {
				reject(error);
			}
		});
	}
}

export class GetTransactionPayload {
	tags: Tag[];
	glTags: GLTag[];
	q: string;
	positionType: string;
	from: number;
	params: SearchParameter[];
	size: number;
	fullAccountNumbers: boolean;
	tqlJSONExpression: Object;
}
