import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Tag, TagType, TagCSVPayload, TagDependentObjects } from '../models/tag.model';
import { TransactionFilterType } from '../../../shared/models/transaction-filters.model';
import { firstValueFrom, Observable, throwError } from 'rxjs';
import { Store } from '@ngxs/store';
import { UpdateFilter } from 'src/app/shared/store/actions/filter-object.actions';
import { GetGlTags, GetTags } from '../store/actions/tags.action';
import { TQLPropertyKey } from '@trovata/app/shared/models/tql.model';
import { GetValues } from '@trovata/app/shared/store/actions/tql-fields.actions';

@Injectable({
	providedIn: 'root',
})
export class TagService {
	constructor(
		private httpClient: HttpClient,
		private store: Store
	) {}

	public deleteV2Tag(tag: Tag): Observable<HttpResponse<void>> {
		const url: string = environment.trovataAPI('workspace') + '/data/tags/' + tag.tagId;
		return this.httpClient
			.delete<void>(url, {
				observe: 'response',
			})
			.pipe(catchError(err => throwError(err)));
	}

	public getTagDependentObjects(tagId: string): Observable<HttpResponse<TagDependentObjects>> {
		const url: string = `${environment.trovataAPI('workspace')}/data/v5/tql/tags/dependent-objects/${tagId}`;
		return this.httpClient
			.get<TagDependentObjects>(url, {
				observe: 'response',
			})
			.pipe(catchError(err => throwError(() => err)));
	}

	public deleteTQLTag(tag: Tag): Observable<HttpResponse<void>> {
		const url: string = environment.trovataAPI('workspace') + '/data/tql/tags/' + tag.tagId;
		return this.httpClient
			.delete<void>(url, {
				observe: 'response',
			})
			.pipe(catchError(err => throwError(err)));
	}

	createTQLTag(tag: Tag): Observable<HttpResponse<{ tagId: string }>> {
		const url: string = environment.trovataAPI('workspace') + '/data/tql/tags';
		const tagPostBody = {
			tagTitle: tag.tagTitle,
			tagDescription: tag.tagDescription,
			tagType: tag.tagType,
			includeTransactions: tag.tagMetadata.includeTransactions || [],
			excludeTransactions: tag.tagMetadata.excludeTransactions || [],
			tql: tag.tagMetadata.tql,
		};
		return this.httpClient.post<{ tagId: string }>(url, tagPostBody, {
			observe: 'response',
		});
	}

	editTQLTag(tag: Tag): Observable<HttpResponse<void>> {
		const url: string = environment.trovataAPI('workspace') + '/data/tql/tags/' + tag.tagId;
		const tagPostBody = {
			tagTitle: tag.tagTitle,
			tagDescription: tag.tagDescription,
			tagType: tag.tagType,
			includeTransactions: tag.tagMetadata.includeTransactions || [],
			excludeTransactions: tag.tagMetadata.excludeTransactions || [],
			tql: tag.tagMetadata.tql,
		};
		return this.httpClient.put<void>(url, tagPostBody, {
			observe: 'response',
		});
	}

	tagsCSVExport(exportOptions: TagCSVPayload): Observable<HttpResponse<string>> {
		const url: string = environment.trovataAPI('workspace') + '/data/tql/tags/export';
		return this.httpClient.post<string>(url, exportOptions, {
			observe: 'response',
		});
	}

	public actionTag(tag: Tag, transactionArray: Array<string>, action: string) {
		const url: string = `${environment.trovataAPI('workspace')}/data/tags/${tag.tagId}/transactions?action=${action}`;
		return this.httpClient.put(
			url,
			{
				transactionIds: transactionArray,
			},
			{
				observe: 'response',
			}
		);
	}

	public getTags(): Observable<HttpResponse<Tag[]>> {
		const body = { tagType: TagType.transaction };

		const url: string = environment.trovataAPI('workspace') + '/data/tql/tags-list';

		return this.httpClient.post<Tag[]>(url, body, {
			observe: 'response',
		});
	}

	public async updateStoreAndFilters(tagType: TagType) {
		if (tagType === TagType.glTag) {
			await firstValueFrom(this.store.dispatch(new GetGlTags()));
			this.store.dispatch(new UpdateFilter('transaction', TransactionFilterType.glTags));
			this.store.dispatch(new GetValues([TQLPropertyKey.glTag]));
		} else if (tagType === TagType.glTagAuto) {
			await firstValueFrom(this.store.dispatch(new GetGlTags()));
			this.store.dispatch(new UpdateFilter('transaction', TransactionFilterType.glAutoTags));
			this.store.dispatch(new GetValues([TQLPropertyKey.glTag]));
		} else {
			await firstValueFrom(this.store.dispatch(new GetTags()));
			this.store.dispatch(new UpdateFilter('transaction', TransactionFilterType.tags));
			this.store.dispatch(new GetValues([TQLPropertyKey.tag]));
		}
	}
}
