import { Injectable } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { firstValueFrom, Observable } from 'rxjs';
import { GLCode, GlCodePayload, BulkGlCodesPayload } from '../../models/gl-code.model';
import {
	ClearLastCreatedGlCodeId,
	ClearLastCreatedBulkGlCodeIds,
	CreateGlCode,
	CreateBulkGlCodes,
	DeleteGlCode,
	UpdateGlCode,
} from '../../store/actions/glCode.action';
import { GlCodesState } from '../../store/state/glCode.state';
import { GlTagsState } from '../../store/state/glTags.state';
import { firstValidValueFrom } from '@trovata/app/shared/utils/firstValidValueFrom';
import { GLTag } from '../../models/glTag.model';

@Injectable({
	providedIn: 'root',
})
export class GlCodeFacadeService {
	@Select(GlCodesState.glCodes) glCodes$: Observable<GLCode[]>;
	@Select(GlTagsState.glTags) glTags$: Observable<GLTag[]>;
	@Select(GlCodesState.lastCreatedGlCodeId)
	lastCreatedGlCodeId$: Observable<string>;
	@Select(GlCodesState.lastCreatedBulkGlCodeIds)
	lastCreatedBulkGlCodeIds$: Observable<string[]>;

	constructor(private store: Store) {}

	getGlCodes(): Promise<GLCode[]> {
		return new Promise(async (resolve, reject) => {
			try {
				this.glCodes$.subscribe(async (glCodes: GLCode[]) => {
					if (glCodes) {
						await this.addAppliedTagsToGlCodes(glCodes);
						resolve(glCodes);
					}
				});
			} catch (error) {
				reject(error);
			}
		});
	}

	private async addAppliedTagsToGlCodes(glCodes: GLCode[]): Promise<void> {
		try {
			const glTags: GLTag[] = await this.getGLTags();
			glCodes.forEach((code: GLCode) => {
				glTags.forEach((tag: GLTag) => {
					if (code.codeId === tag.glCode1) {
						if (!code.appliedTags) {
							code.appliedTags = [];
						}
						code.appliedTags.push(tag.title);
					}
					if (code.codeId === tag.glCode2) {
						if (!code.appliedTags) {
							code.appliedTags = [];
						}
						code.appliedTags.push(tag.title);
					}
				});
			});
		} catch (error) {
			throw new Error(error);
		}
	}

	private getGLTags(): Promise<GLTag[]> {
		return firstValidValueFrom(this.glTags$);
	}

	getGlCode(codeId: string): Promise<GLCode> {
		return new Promise(async (resolve, reject) => {
			try {
				const glCodes: GLCode[] = await this.getGlCodes();
				const selectedGlCode: GLCode = glCodes.find((glCode: GLCode) => glCode.codeId === codeId);
				resolve(selectedGlCode);
			} catch (error) {
				reject(error);
			}
		});
	}

	getGlCodesById(codeIds: string[]): Promise<GLCode[]> {
		return new Promise(async (resolve, reject) => {
			try {
				const glCodes: GLCode[] = await this.getGlCodes();
				const selectedGlCodes: GLCode[] = glCodes.filter((glCode: GLCode) => codeIds.includes(glCode.codeId));
				resolve(selectedGlCodes);
			} catch (error) {
				reject(error);
			}
		});
	}

	createGlCode(glCodePayload: GlCodePayload): Promise<GLCode> {
		return new Promise(async (resolve, reject) => {
			try {
				await firstValueFrom(this.store.dispatch(new CreateGlCode(glCodePayload)));
				let lastCreatedGlCodeId: string;
				this.lastCreatedGlCodeId$.subscribe((id: string) => (lastCreatedGlCodeId = id));
				const newGlCode: GLCode = await this.getGlCode(lastCreatedGlCodeId);
				const newGlCodeCopy: GLCode = JSON.parse(JSON.stringify(newGlCode));
				this.store.dispatch(new ClearLastCreatedGlCodeId());
				resolve(newGlCodeCopy);
			} catch (error) {
				reject(error);
			}
		});
	}

	createBulkGlCodes(bulkGlCodePayload: BulkGlCodesPayload): Promise<GLCode[]> {
		return new Promise(async (resolve, reject) => {
			try {
				await firstValueFrom(this.store.dispatch(new CreateBulkGlCodes(bulkGlCodePayload)));
				const lastCreatedBulkGlCodeIds: string[] = await firstValueFrom(this.lastCreatedBulkGlCodeIds$);
				const newGlCodes: GLCode[] = await this.getGlCodesById(lastCreatedBulkGlCodeIds);
				const newGlCodesCopy: GLCode[] = JSON.parse(JSON.stringify(newGlCodes));
				this.store.dispatch(new ClearLastCreatedBulkGlCodeIds());
				resolve(newGlCodesCopy);
			} catch (error) {
				reject(error);
			}
		});
	}

	updateGlCode(glCodePayload: GlCodePayload, codeId: string): Promise<GLCode> {
		return new Promise(async (resolve, reject) => {
			try {
				await firstValueFrom(this.store.dispatch(new UpdateGlCode(glCodePayload, codeId)));
				const updatedGlCode: GLCode = await this.getGlCode(codeId);
				const updatedGlCodeCopy: GLCode = JSON.parse(JSON.stringify(updatedGlCode));
				resolve(updatedGlCodeCopy);
			} catch (error) {
				reject(error);
			}
		});
	}

	deleteGlCode(codeId: string): Promise<void> {
		return new Promise(async (resolve, reject) => {
			try {
				await firstValueFrom(this.store.dispatch(new DeleteGlCode(codeId)));
				resolve();
			} catch (error) {
				reject(error);
			}
		});
	}
}
