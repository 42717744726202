import { TagType } from './tag.model';

export interface GLTag {
	glTagId: string;
	title: string;
	description: string;
	type: TagType;
	priority: number;
	glCode1: string;
	glCode2: string;
	tql: any;
}

export interface GLTagPayload {
	glTagId?: string;
	title: string;
	description: string;
	type: TagType;
	priority?: number;
	glCode1: string;
	glCode2: string;
	tql: any;
}

export enum GLTagConvertStatement {
	manual = "Changing this G/L Tag from automatic to manual will stop it from automatically applying to future transactions. Past transactions won't change, and re-tagging won't affect them.",
	automatic = `Switching this G/L Tag to automatic means it will apply to new transactions automatically. 
		To update past transactions, use the Re-Tag function.`,
}

export enum GLTagAction {
	include = 'add',
	exclude = 'remove',
}
export class PostGLTagResponse {
	tagId: string;
}

export interface GLTagActionBody {
	action: GLTagAction;
	tql?: Object;
	transactions?: string[];
}

export class GetGlTagsReponse {
	glTags: GLTag[];
}

export class PutGlAutoPriorityResponse {
	glTags: GLTag[];
}
