import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse, HttpParams } from '@angular/common/http';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Observable, throwError } from 'rxjs';
import { GetGlTagsReponse, GLTag, GLTagAction, GLTagActionBody, GLTagPayload, PutGlAutoPriorityResponse, PostGLTagResponse } from '../models/glTag.model';
import { TagType } from '../models/tag.model';

@Injectable({
	providedIn: 'root',
})
export class GLTagService {
	constructor(private httpClient: HttpClient) {}

	public createGLTag(tag: GLTag): Observable<HttpResponse<PostGLTagResponse>> {
		const url: string = `${environment.trovataAPI('workspace')}/data/v5/gl-tags`;
		const tagPostBody: GLTagPayload = {
			title: tag.title,
			description: tag.description,
			type: tag.type,
			tql: tag.tql,
			glCode1: tag.glCode1,
			glCode2: tag.glCode2,
		};
		return this.httpClient.post<PostGLTagResponse>(url, tagPostBody, {
			observe: 'response',
		});
	}

	public editGLTag(tag: GLTag): Observable<HttpResponse<void>> {
		const url: string = `${environment.trovataAPI('workspace')}/data/v5/gl-tags/${tag.glTagId}`;
		const tagPostBody: GLTagPayload = {
			glTagId: tag.glTagId,
			title: tag.title,
			description: tag.description,
			type: tag.type,
			tql: tag.tql,
			glCode1: tag.glCode1,
			glCode2: tag.glCode2,
		};
		return this.httpClient
			.put<void>(url, tagPostBody, {
				observe: 'response',
			})
			.pipe(catchError(err => throwError(err)));
	}

	public deleteGLTag(tag: GLTag): Observable<HttpResponse<void>> {
		const url: string = `${environment.trovataAPI('workspace')}/data/v5/gl-tags/${tag.glTagId}`;
		return this.httpClient
			.delete<void>(url, {
				observe: 'response',
			})
			.pipe(catchError(err => throwError(err)));
	}

	public getGLTags(): Observable<HttpResponse<GetGlTagsReponse>> {
		const body = {
			tagType: [TagType.glTag, TagType.glTagAuto],
		};

		const url: string = `${environment.trovataAPI('workspace')}/data/v5/gl-tags/list`;

		return this.httpClient.post<GetGlTagsReponse>(url, body, {
			observe: 'response',
		});
	}

	public putGlAutoPriority(glTagIds: string[]): Observable<HttpResponse<PutGlAutoPriorityResponse>> {
		const url: string = `${environment.trovataAPI('workspace')}/data/v5/gl-tags/priority`;
		return this.httpClient.put<PutGlAutoPriorityResponse>(
			url,
			{
				glTagIds: glTagIds,
			},
			{
				observe: 'response',
			}
		);
	}

	public glAutoReTag(days: number): Observable<HttpResponse<void>> {
		const url: string = `${environment.trovataAPI('workspace')}/data/v5/gl-tags/retag`;
		let params: HttpParams = new HttpParams();
		if (days) {
			params = params.append('priorDays', days);
		}
		return this.httpClient.post<void>(
			url,
			{},
			{
				params: params,
				observe: 'response',
			}
		);
	}

	public glTagTransactions(glTagId: string, action: GLTagAction, transactionIds: string[]): Observable<HttpResponse<void>> {
		const url: string = `${environment.trovataAPI('workspace')}/data/v5/gl-tags/${glTagId}/transactions`;
		const body: GLTagActionBody = {
			action: action,
			transactions: transactionIds,
		};
		return this.httpClient.patch<void>(url, body, { observe: 'response' });
	}

	public bulkGLTagTransactions(glTagId: string, action: GLTagAction, tql: Object): Observable<HttpResponse<void>> {
		const url: string = `${environment.trovataAPI('workspace')}/data/v5/gl-tags/${glTagId}/transactions/all`;
		const body: GLTagActionBody = {
			action: action,
		};
		if (action === GLTagAction.include && tql) {
			body.tql = {
				type: 'AST',
				expression: tql,
			};
		}
		return this.httpClient.patch<void>(url, body, { observe: 'response' });
	}
}
