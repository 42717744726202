import { GLCode } from '../../models/gl-code.model';
import { GLTag } from '../../models/glTag.model';

export class InitGlTagsState {
	static readonly type: string = '[GlTag] InitGlTagsState';
}

export class ClearGlTagsState {
	static readonly type: string = '[GlTag] ClearGlTagsState';
}

export class ResetGlTagsState {
	static readonly type: string = '[GlTag] ResetGlTagsState';
}

export class GetGlTags {
	static readonly type: string = '[GlTag] GetGlTags';
	constructor() {}
}

export class CreateGlTag {
	static readonly type: string = '[GlTag] CreateGlTag';
	constructor(public tag: GLTag) {}
}

export class AddGlTagToState {
	static readonly type: string = '[GlTag] AddGlTagToState';
	constructor(public tag: GLTag) {}
}

export class DeleteGlTag {
	static readonly type: string = '[GlTag] DeleteGlTag';
	constructor(public tag: GLTag) {}
}

export class UpdateGlTag {
	static readonly type: string = '[GlTag] UpdateGlTag';
	constructor(public tag: GLTag) {}
}

export class CreateGlCode {
	static readonly type: string = '[GlTag] CreateGlCode';
	constructor(public glCode: GLCode) {}
}
export class DeleteGlCode {
	static readonly type: string = '[GlTag] DeleteGlCode';
	constructor(public glCode: GLCode) {}
}
export class UpdateGlCode {
	static readonly type: string = '[GlTag] UpdateGlCode';
	constructor(public glCode: GLCode) {}
}

export class ClearLastCreatedGlTagId {
	static readonly type: string = '[GlTag] ClearLastCreatedGlTagId';
}

export class SetLastCreatedGlTagId {
	static readonly type: string = '[GlTag] SetLastCreatedGlTagId';
	constructor(public id: string) {}
}

export class PutGlAutoPriority {
	static readonly type: string = '[GlTag] PutGlAutoPriority';
	constructor(public glTagIds: string[]) {}
}
