import { Injectable } from '@angular/core';
import { HttpResponse, HttpClient, HttpParams } from '@angular/common/http';
import { RequestThrottler } from '../../reports/utils/request-throttle';
import { Observable } from 'rxjs';
import { StreamPreviewPayload } from '../models/forecastV3-stream-preview';
import { StreamMLDataSourceSettings, IStreamPayload, StreamValue } from '../models/forecastV3-stream.model';
import { IForecastPayload, IGlobalFactorsPayload } from '../models/forecastV3-forecast.model';
import { IForecastV3Response, StreamGroupPayload } from '../models/forecastV3-forecast-response.model';
import { environment } from 'src/environments/environment';
import { ForecastAudit, ForecastVersion } from '../models/forecastV3-version.model';
import { Stream } from 'stream';

@Injectable({
	providedIn: 'root',
})
export class ForecastV3Service extends RequestThrottler {
	constructor(public httpClient: HttpClient) {
		super(30);
	}

	public getStreams(): Observable<HttpResponse<Stream[]>> {
		const url = `${environment.trovataAPI('workspace')}/data/forecasts/streams-list`;
		return this.httpClient.post<Stream[]>(
			url,
			{},
			{
				observe: 'response',
			}
		);
	}

	public getStreamData(streamId, startDate?: string, endDate?: string, cadence?: string, currencyOverride?: string) {
		const body = {};
		if (cadence) {
			body['cadence'] = cadence;
		}
		if (startDate) {
			body['startDate'] = startDate;
		}
		if (endDate) {
			body['endDate'] = endDate;
		}
		if (currencyOverride) {
			body['currencyOverride'] = currencyOverride;
		}
		const url = `${environment.trovataAPI('workspace')}/data/forecasts/streams/${streamId}`;
		return this.httpClient.post(url, body, {
			observe: 'response',
		});
	}

	public getForecastData(forecastId: string, cadence: string, startDate?: string, endDate?: string): Observable<HttpResponse<IForecastV3Response>> {
		const body = {};
		if (cadence) {
			body['cadence'] = cadence;
		}
		if (startDate) {
			body['startDate'] = startDate;
		}
		if (endDate) {
			body['endDate'] = endDate;
		}
		const url = `${environment.trovataAPI('workspace')}/data/forecasts/${forecastId}`;
		return this.httpClient.post<IForecastV3Response>(url, body, {
			observe: 'response',
		});
	}

	public getForecasts(): Observable<HttpResponse<IForecastV3Response>> {
		const url = `${environment.trovataAPI('workspace')}/data/forecasts/forecasts-list`;
		return this.httpClient.post<IForecastV3Response>(
			url,
			{},
			{
				observe: 'response',
			}
		);
	}

	public createStream(streamPayload: IStreamPayload, duplicateStreamId?: string, newStreamName?: string) {
		const url = `${environment.trovataAPI('workspace')}/data/forecasts/streams`;
		let params = new HttpParams();
		if (duplicateStreamId) {
			params = params.append('duplicateStreamById', duplicateStreamId);
			params = params.append('nameDuplicate', newStreamName);
		}
		return this.httpClient.post(url, streamPayload, {
			params: params,
			observe: 'response',
		});
	}

	public createForecast(forecastPayload: IForecastPayload, duplicateForecastById?: string, newForecastName?: string) {
		const url = `${environment.trovataAPI('workspace')}/data/forecasts`;
		let params = new HttpParams();
		if (duplicateForecastById) {
			params = params.append('duplicateForecastById', duplicateForecastById);
			params = params.append('nameDuplicate', newForecastName);
		}
		return this.httpClient.post(url, forecastPayload, {
			params: params,
			observe: 'response',
		});
	}

	public createStreamPreview(streamPreviewPayload: StreamPreviewPayload): Observable<HttpResponse<StreamMLDataSourceSettings>> {
		const url = `${environment.trovataAPI('workspace')}/data/forecasts/streams/preview`;
		return this.httpClient.post<StreamMLDataSourceSettings>(url, streamPreviewPayload, {
			observe: 'response',
		});
	}

	public deleteStream(streamId: string) {
		const url = `${environment.trovataAPI('workspace')}/data/forecasts/streams/${streamId}`;
		return this.httpClient.delete(url, {
			observe: 'response',
		});
	}

	public deleteForecast(forecastId: string) {
		const url = `${environment.trovataAPI('workspace')}/data/forecasts/${forecastId}`;
		return this.httpClient.delete(url, {
			observe: 'response',
		});
	}

	public updateStreamData(streamId: string, streamValues: StreamValue[]) {
		const url = `${environment.trovataAPI('workspace')}/data/forecasts/streams/${streamId}/data`;
		return this.httpClient.patch(url, streamValues, {
			observe: 'response',
		});
	}

	public updateStream(streamId: string, streamPayload: IStreamPayload) {
		const url = `${environment.trovataAPI('workspace')}/data/forecasts/streams/${streamId}`;
		return this.httpClient.patch(url, streamPayload, {
			observe: 'response',
		});
	}

	public updateForecast(forecastId: string, forecastPayload: IForecastPayload) {
		const url = `${environment.trovataAPI('workspace')}/data/forecasts/${forecastId}`;
		return this.httpClient.patch(url, forecastPayload, {
			observe: 'response',
		});
	}

	public createStreamGroup(forecastId: string, streamGroup: StreamGroupPayload) {
		const url = `${environment.trovataAPI('workspace')}/data/forecasts/${forecastId}/stream-groups`;
		return this.httpClient.post(url, streamGroup, {
			observe: 'response',
		});
	}

	public editStreamGroup(forecastId: string, streamGroupId: string, streamGroup: StreamGroupPayload) {
		const url = `${environment.trovataAPI('workspace')}/data/forecasts/${forecastId}/stream-groups/${streamGroupId}`;
		return this.httpClient.patch(url, streamGroup, {
			observe: 'response',
		});
	}

	public deleteStreamGroup(forecastId: string, streamGroupId: string) {
		const url = `${environment.trovataAPI('workspace')}/data/forecasts/${forecastId}/stream-groups/${streamGroupId}`;
		return this.httpClient.delete(url, {
			observe: 'response',
		});
	}

	public createGlobalFactors(globalFactorsPayload: IGlobalFactorsPayload[], forecastId: string) {
		const url = `${environment.trovataAPI('workspace')}/data/forecasts/${forecastId}/factors`;
		return this.httpClient.post(url, globalFactorsPayload, {});
	}

	public editGlobalFactor(forecastId: string, factorId: string, factorPayload: IGlobalFactorsPayload) {
		factorPayload.streamId = undefined; // Fix for API
		const url = `${environment.trovataAPI('workspace')}/data/forecasts/${forecastId}/factors/${factorId}`;
		return this.httpClient.patch(url, factorPayload, {
			observe: 'response',
		});
	}

	public deleteGlobalFactor(forecastId: string, factorId: string) {
		const url = `${environment.trovataAPI('workspace')}/data/forecasts/${forecastId}/factors/${factorId}`;
		return this.httpClient.delete(url, {
			observe: 'response',
		});
	}

	public getVersions(forecastId: string, startDate: string, endDate: string): Observable<HttpResponse<ForecastVersion[]>> {
		const url = `${environment.trovataAPI('workspace')}/data/forecasts/versions-list`;
		const body = {};
		body['objectId'] = forecastId;
		body['objectType'] = 'forecast';
		body['startDate'] = startDate;
		body['endDate'] = endDate;

		return this.httpClient.post<ForecastVersion[]>(url, body, {
			observe: 'response',
		});
	}

	public getVersionById(versionId: string): Observable<HttpResponse<IForecastV3Response>> {
		const url = `${environment.trovataAPI('workspace')}/data/forecasts/versions/${versionId}`;
		return this.httpClient.post<IForecastV3Response>(
			url,
			{},
			{
				observe: 'response',
			}
		);
	}

	public getAudits(forecastId: string, startDate: string, endDate: string): Observable<HttpResponse<ForecastAudit[]>> {
		const url = `${environment.trovataAPI('workspace')}/data/forecasts/audits-list`;
		const body = {};

		body['auditObjectId'] = forecastId;
		body['startDate'] = startDate;
		body['endDate'] = endDate;

		return this.httpClient.post<ForecastAudit[]>(url, body, {
			observe: 'response',
		});
	}

	public getAuditsById(auditId: string): Observable<HttpResponse<ForecastAudit>> {
		const url = `${environment.trovataAPI('workspace')}/data/forecasts/audits/${auditId}`;
		return this.httpClient.post<ForecastAudit>(
			url,
			{},
			{
				observe: 'response',
			}
		);
	}
}
