import { Injectable } from '@angular/core';
import { Action, Select, Selector, State, StateContext, Store } from '@ngxs/store';
import { TrovataAppState } from 'src/app/core/models/state.model';
import { combineLatest, firstValueFrom, Observable, Subscription, tap, throwError } from 'rxjs';
import { ForecastV3Service } from '../../services/forecastV3.service';
import {
	AddForecastDataToState,
	AddStreamDataToState,
	ClearForecastV3State,
	CreateForecast,
	CreateGlobalFactors,
	CreateStream,
	DeleteForecast,
	DeleteStream,
	GetForecasts,
	GetStreams,
	InitForecastV3State,
	LazyLoadForecastData,
	LazyLoadStreamData,
	RetryLazyLoadForecastData,
	UpdateForecast,
	UpdateStream,
	UpdateStreamData,
} from '../actions/forecastV3.action';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Stream, IStreamPayload, StreamValue } from '../../models/forecastV3-stream.model';
import { GlobalFactorItem, IForecastPayload, IGlobalFactorsPayload } from '../../models/forecastV3-forecast.model';
import {
	EditedForecastFactorGroups,
	EditedStreamGroups,
	IForecastV3Response,
	StreamGroupPayload,
	StreamGroupsEntity,
	StreamsEntity,
} from '../../models/forecastV3-forecast-response.model';
import { SerializationService } from 'src/app/core/services/serialization.service';
import { formatDate } from '@angular/common';
import { UpdatedEventsService } from 'src/app/shared/services/updated-events.service';
import { ActionType, ForecastV3UpdatedEvent } from 'src/app/shared/models/updated-events.model';
import { PreferencesFacadeService } from 'src/app/shared/services/facade/preferences.facade.service';
import { SnackType } from 'src/app/shared/models/snacks.model';
import { FeatureId } from '../../../settings/models/feature.model';
import { CustomerFeatureState } from '../../../settings/store/state/customer-feature.state';
import { DateTime } from 'luxon';

export class ForecastV3StateModel {
	forecasts: IForecastV3Response[];
	streams: Stream[];
	preFetchInFlight: boolean;
}

@State<ForecastV3StateModel>({
	name: 'forecastV3',
	defaults: {
		forecasts: null,
		streams: null,
		preFetchInFlight: false,
	},
})
@Injectable()
export class ForecastV3State {
	private isInitialized: boolean;
	@Select(CustomerFeatureState.hasPermission(FeatureId.forecast)) shouldSeeForecasts: Observable<boolean>;

	private appReady$: Observable<boolean>;
	private appReadySub: Subscription;

	constructor(
		private serializationService: SerializationService,
		private store: Store,
		private forecastV3Service: ForecastV3Service,
		private preferencesFacadeService: PreferencesFacadeService,
		private updatedEventsService: UpdatedEventsService
	) {
		this.appReady$ = this.store.select((state: TrovataAppState) => state.core.appReady);
		this.isInitialized = false;
	}

	@Selector() static forecastsV3(state: ForecastV3StateModel): IForecastV3Response[] {
		return state.forecasts;
	}
	@Selector() static streamsV3(state: ForecastV3StateModel): Stream[] {
		return state.streams;
	}

	@Selector() static forecastsPreFetchInFlight(forecastState: ForecastV3StateModel): boolean {
		return forecastState.preFetchInFlight;
	}

	@Action(InitForecastV3State)
	async initForecastV3State(context: StateContext<ForecastV3StateModel>) {
		try {
			const deserializedState: TrovataAppState = await this.serializationService.getDeserializedState();
			const forecastStateIsCached: boolean = this.forecastStateIsCached(deserializedState);
			const streamStateIsCached: boolean = this.streamStateIsCached(deserializedState);
			this.appReadySub = combineLatest([this.appReady$, this.shouldSeeForecasts]).subscribe({
				next: ([appReady, shouldSeeForecasts]: [boolean, boolean]) => {
					if (!this.isInitialized && appReady && shouldSeeForecasts !== undefined) {
						if (shouldSeeForecasts) {
							if (forecastStateIsCached || streamStateIsCached) {
								const state: ForecastV3StateModel = deserializedState.forecastV3;
								context.patchState(state);
							}
							if (!forecastStateIsCached) {
								context.dispatch(new GetForecasts());
							}
							if (!streamStateIsCached) {
								context.dispatch(new GetStreams());
							}
							this.isInitialized = true;
						} else {
							context.patchState({ forecasts: [], streams: [] });
						}
					}
				},
				error: (error: Error) => throwError(() => error),
			});
		} catch (error: any) {
			throwError(() => error);
		}
	}

	@Action(GetForecasts)
	getForecasts(context: StateContext<ForecastV3StateModel>) {
		return this.forecastV3Service.getForecasts().pipe(
			tap((response: HttpResponse<any>) => {
				const forecasts: IForecastV3Response[] = response.body.forecasts;
				this.addForecastsToForecastState(context, forecasts);
			})
		);
	}

	@Action(GetStreams)
	getStreams(context: StateContext<ForecastV3StateModel>) {
		return this.forecastV3Service.getStreams().pipe(
			tap((response: HttpResponse<any>) => {
				const streams: Stream[] = response.body.streams;
				this.addStreamsToForecastState(context, streams);
			})
		);
	}

	@Action(CreateStream)
	createStream(context: StateContext<ForecastV3StateModel>, action: CreateStream) {
		return new Promise<void>(async (resolve, reject) => {
			try {
				const streamPayload: IStreamPayload = action.streamPayload;
				let streamCurrency: string;
				if (!streamPayload && action.duplicateStreamId) {
					const existingStreams: Stream[] = context.getState().streams;
					const duplicatedStream: Stream = existingStreams.find(stream => stream.streamId === action.duplicateStreamId);
					streamCurrency = duplicatedStream.currency;
				} else {
					streamCurrency = streamPayload.currency;
				}
				const newStreamResponse = await firstValueFrom(this.forecastV3Service.createStream(streamPayload, action.duplicateStreamId, action.newStreamName));
				const streamDataResponse = await firstValueFrom(
					this.forecastV3Service.getStreamData(
						<string>newStreamResponse.body['streamId'],
						streamPayload?.startDate,
						streamPayload?.endDate,
						null,
						streamCurrency
					)
				);
				context.dispatch(new AddStreamDataToState(<Stream>streamDataResponse.body));
				resolve();
			} catch (error) {
				reject(new Error('Could not create stream'));
			}
		});
	}

	@Action(CreateForecast)
	createForecast(context: StateContext<ForecastV3StateModel>, action: CreateForecast) {
		return new Promise<void>(async (resolve, reject) => {
			try {
				if (action.duplicateForecastById) {
					// Duplicate
					const duplicateForecastResponse = await firstValueFrom(
						this.forecastV3Service.createForecast(null, action.duplicateForecastById, action.nameDuplicate)
					);
					if (duplicateForecastResponse && duplicateForecastResponse.body['forecastId']) {
						const newForecastId: string = duplicateForecastResponse.body['forecastId'];
						const startDate: Date = new Date();
						const startDateFormat: string = formatDate(startDate, 'yyyy-MM-dd', 'en-US', 'UTC');
						this.forecastV3Service.getForecastData(newForecastId, action.cadence, startDateFormat).subscribe((result: HttpResponse<IForecastV3Response>) => {
							const getForecastDataResponse: IForecastV3Response = result.body;
							const state: ForecastV3StateModel = context.getState();
							const sortedForecasts: IForecastV3Response[] = this.sortByName([getForecastDataResponse, ...state.forecasts]);
							state.forecasts = sortedForecasts;
							context.patchState(state);
							resolve();
						});
					}
				} else {
					// Create New
					const forecastPayload: IForecastPayload = action.forecastPayload;
					this.forecastV3Service.createForecast(forecastPayload, action.duplicateForecastById).subscribe(result => {
						const forecastId: string = result.body['forecastId'];
						this.forecastV3Service
							.getForecastData(forecastId, forecastPayload.cadence, forecastPayload.startDate, forecastPayload.endDate)
							.subscribe(forecastResult => {
								context.dispatch(new AddForecastDataToState(<IForecastV3Response>forecastResult.body));
								resolve();
							});
					});
				}
			} catch (error) {
				reject(new Error('Could not create forecast'));
			}
		});
	}

	@Action(LazyLoadForecastData)
	async lazyLoadForecastData(context: StateContext<ForecastV3StateModel>, action: LazyLoadForecastData): Promise<void> {
		return new Promise(async (resolve, reject) => {
			try {
				const state: ForecastV3StateModel = context.getState();
				const forecast: IForecastV3Response = state.forecasts.find((findForecast: IForecastV3Response) => findForecast.forecastId === action.forecastId);
				await this.lazyLoadForecastsData(context, forecast);
				resolve();
			} catch (error) {
				reject(error);
			}
		});
	}

	@Action(LazyLoadStreamData)
	async lazyLoadStreamData(context: StateContext<ForecastV3StateModel>, action: LazyLoadStreamData): Promise<void> {
		return new Promise(async (resolve, reject) => {
			try {
				const state: ForecastV3StateModel = context.getState();
				const stream: Stream = state.streams.find((findStream: Stream) => findStream.streamId === action.streamId);
				await this.lazyLoadStreamsData(context, stream);
				resolve();
			} catch (error) {
				reject(error);
			}
		});
	}

	@Action(CreateGlobalFactors)
	createGlobalFactors(context: StateContext<ForecastV3StateModel>, action: CreateGlobalFactors) {
		return new Promise<void>(async (resolve, reject) => {
			try {
				const globalFactorsFayload: IGlobalFactorsPayload[] = action.forecastFactorsPayload;
				const forecastId: string = action.forecastId;
				this.forecastV3Service.createGlobalFactors(globalFactorsFayload, forecastId).subscribe(() => {
					this.forecastV3Service.getForecastData(forecastId, action.cadence).subscribe(async forecastResult => {
						await this.patchChangedStateForecasts(context, [forecastResult.body]);
						resolve();
					});
				});
			} catch (error) {
				reject(new Error('Could not create forecast'));
			}
		});
	}

	@Action(UpdateStreamData)
	updateStreamData(context: StateContext<ForecastV3StateModel>, action: UpdateStreamData) {
		return new Promise<void>(async (resolve, reject) => {
			try {
				const streamValuePayload: StreamValue[] = action.streamValues;
				await firstValueFrom(this.forecastV3Service.updateStreamData(action.streamId, streamValuePayload));
				await this.locallyUpdateStreamData(context, action.streamId, streamValuePayload);
				await this.updateForecastsForDeletedStreams(context, action.streamId, action.parentForecast);
				resolve();
			} catch (error) {
				reject(new Error('Could not update stream'));
			}
		});
	}

	@Action(UpdateStream)
	updateStream(context: StateContext<ForecastV3StateModel>, action: UpdateStream) {
		return new Promise<void>(async (resolve, reject) => {
			try {
				const streamPayload: IStreamPayload = action.streamPayload;
				await firstValueFrom(this.forecastV3Service.updateStream(action.streamId, streamPayload));
				if (action.values) {
					await firstValueFrom(this.forecastV3Service.updateStreamData(action.streamId, action.values));
				}
				const streamResponse = await firstValueFrom(this.forecastV3Service.getStreams());
				const stream: Stream = <Stream>streamResponse.body['streams'].find(updatedStream => updatedStream.streamId === action.streamId);
				let startDate: string;
				let endDate: string;
				if (!stream.isRolling) {
					startDate = stream.startDate;
					endDate = stream.endDate;
				} else {
					const currentDate: DateTime = DateTime.now();
					let lStartDate: DateTime;
					if (stream.rollingOffset < 0) {
						lStartDate = currentDate.minus({ days: Math.abs(stream.rollingOffset) });
					} else {
						lStartDate = currentDate.plus({ days: stream.rollingOffset });
					}
					const lEndDate: DateTime = lStartDate.plus({ days: stream.rollingPeriods - 1 });
					startDate = lStartDate.toFormat('yyyy-MM-dd');
					endDate = lEndDate.toFormat('yyyy-MM-dd');
				}
				const streamDataResponse = await firstValueFrom(this.forecastV3Service.getStreamData(action.streamId, startDate, endDate, null, stream.currency));
				context.dispatch(new AddStreamDataToState(<Stream>streamDataResponse.body));
				await this.updateForecastsForDeletedStreams(context, action.streamId, action.parentForecast);
				resolve();
			} catch (error) {
				reject(new Error('Could not update stream'));
			}
		});
	}

	@Action(UpdateForecast)
	updateForecast(context: StateContext<ForecastV3StateModel>, action: UpdateForecast) {
		return new Promise<void>(async (resolve, reject) => {
			try {
				const forecastPayload: IForecastPayload = action.forecastPayload;
				if (Object.keys(forecastPayload).length > 0) {
					await firstValueFrom(this.forecastV3Service.updateForecast(action.forecastId, forecastPayload));
				}
				if ((action.globalFactorsSource?.length && action.editedGlobalFactors) || action.editedGlobalFactors?.deletedGlobalFactorIds?.length) {
					await this.updateGlobalFactors(action.forecastId, action.globalFactorsSource, action.editedGlobalFactors);
				}
				if ((action.streamGroupsSource?.length && action.editedStreamGroups) || action.editedStreamGroups?.deletedStreamGroupIds?.length) {
					await this.updateStreamGroups(action.forecastId, action.streamGroupsSource, action.editedStreamGroups);
				}
				const forecastResponse: HttpResponse<IForecastV3Response> = await firstValueFrom(this.forecastV3Service.getForecasts());
				const forecast: IForecastV3Response = forecastResponse.body['forecasts'].find(updatedForecast => updatedForecast.forecastId === action.forecastId);
				let startDate: string;
				let endDate: string;
				if (!forecast.isRolling) {
					startDate = forecast.startDate;
					endDate = forecast.endDate;
				} else {
					const currentDate: DateTime = DateTime.now();
					let lStartDate: DateTime;
					if (forecast.rollingOffset < 0) {
						lStartDate = currentDate.minus({ days: Math.abs(forecast.rollingOffset) });
					} else {
						lStartDate = currentDate.plus({ days: forecast.rollingOffset });
					}
					const lEndDate: DateTime = lStartDate.plus({ days: forecast.rollingPeriods - 1 });
					startDate = lStartDate.toFormat('yyyy-MM-dd');
					endDate = lEndDate.toFormat('yyyy-MM-dd');
				}
				const forecastDataResponse: HttpResponse<IForecastV3Response> = await firstValueFrom(
					this.forecastV3Service.getForecastData(action.forecastId, forecast.cadence, startDate, endDate)
				);
				const fullForecast: IForecastV3Response = forecastDataResponse.body;
				const stateCopy: ForecastV3StateModel = JSON.parse(JSON.stringify(context.getState()));
				const forecastIndex: number = stateCopy.forecasts.findIndex((filter: IForecastV3Response) => forecast.forecastId === filter.forecastId);

				await this.patchChangedStateForecasts(context, [fullForecast]);

				stateCopy.forecasts[forecastIndex] = fullForecast;

				context.patchState({ forecasts: stateCopy.forecasts });
				this.updateForecastsForDeletedForecasts(context, action.forecastId);
				resolve();
			} catch (e) {
				reject(e);
			}
		});
	}

	async updateStreamGroups(forecastId: string, streamGroups: StreamGroupsEntity[], editedStreamGroups: EditedStreamGroups) {
		return new Promise<void>(async (resolve, reject) => {
			try {
				const requests = [];
				const streamGroupsPayload: StreamGroupPayload[] = [];
				streamGroups.forEach(streamGroup => {
					const streamGroupPayload: StreamGroupPayload = {
						name: streamGroup.name,
						description: streamGroup.description,
						streamGroupId: streamGroup.streamGroupId,
						streams: streamGroup.streams.map(stream => stream.streamId),
					};
					streamGroupsPayload.push(streamGroupPayload);
				});

				if (editedStreamGroups.deletedStreamGroupIds.length > 0) {
					editedStreamGroups.deletedStreamGroupIds.forEach(streamGroupId => {
						requests.push(firstValueFrom(this.forecastV3Service.deleteStreamGroup(forecastId, streamGroupId)));
					});
				}
				if (editedStreamGroups.addedStreamGroupIds.length > 0) {
					editedStreamGroups.addedStreamGroupIds.forEach(streamGroupId => {
						const addedStreamGroup: StreamGroupPayload = streamGroupsPayload.find(streamGroup => streamGroup.streamGroupId === streamGroupId);
						if (addedStreamGroup) {
							delete addedStreamGroup.streamGroupId;
							requests.push(firstValueFrom(this.forecastV3Service.createStreamGroup(forecastId, addedStreamGroup)));
						}
					});
				}
				if (editedStreamGroups.updatedStreamGroupIds.length > 0) {
					editedStreamGroups.updatedStreamGroupIds.forEach(streamGroupId => {
						const updatedStreamGroup: StreamGroupPayload = streamGroupsPayload.find(streamGroup => streamGroup.streamGroupId === streamGroupId);
						if (updatedStreamGroup) {
							delete updatedStreamGroup.streamGroupId;
							requests.push(this.forecastV3Service.editStreamGroup(forecastId, streamGroupId, updatedStreamGroup).toPromise());
						}
					});
				}
				if (requests.length > 0) {
					await Promise.all(requests);
				}
				resolve();
			} catch (e) {
				reject(new Error('Could not update stream groups' + e?.toString()));
			}
		});
	}

	async updateGlobalFactors(forecastId: string, forecastFactors: GlobalFactorItem[], editedForecastFactors: EditedForecastFactorGroups) {
		return new Promise<void>(async (resolve, reject) => {
			try {
				const requests = [];
				const factorsPayload: IGlobalFactorsPayload[] = [];
				forecastFactors.forEach(factor => {
					const factorPayload: IGlobalFactorsPayload = {
						id: factor.factorId,
						name: factor.streamName ? factor.streamName : '',
						startDate: factor.startDate,
						endDate: factor.endDate,
						cadence: factor.factorInterval,
						type: factor.factorType,
						value: factor.factorValue,
						streamId: factor.streamId,
					};
					factorPayload.id = factor.factorId;
					factorsPayload.push(factorPayload);
				});

				if (editedForecastFactors.deletedGlobalFactorIds.length > 0) {
					editedForecastFactors.deletedGlobalFactorIds.forEach(factorId => {
						requests.push(this.forecastV3Service.deleteGlobalFactor(forecastId, factorId).toPromise());
					});
				}
				if (editedForecastFactors.addedGlobalFactor.length > 0) {
					const newFactors = factorsPayload.filter(f => !f.id);
					requests.push(this.forecastV3Service.createGlobalFactors(newFactors, forecastId).toPromise());
				}
				if (editedForecastFactors.updatedGlobalFactors.length > 0) {
					editedForecastFactors.updatedGlobalFactors.forEach((globalFactor: IGlobalFactorsPayload) => {
						const updatedFactor: IGlobalFactorsPayload = factorsPayload.find(factorInPayload => factorInPayload.id === globalFactor.id);
						const idForFunction = updatedFactor.id;
						updatedFactor.id = undefined;
						if (updatedFactor) {
							requests.push(this.forecastV3Service.editGlobalFactor(forecastId, idForFunction, updatedFactor).toPromise());
						}
					});
				}
				if (requests.length > 0) {
					await Promise.all(requests);
				}
				resolve();
			} catch (e) {
				reject(new Error('Could not update global factors' + e?.toString()));
			}
		});
	}

	@Action(DeleteStream)
	deleteStream(context: StateContext<ForecastV3StateModel>, action: DeleteStream) {
		return new Promise<void>(async (resolve, reject) => {
			try {
				const streamIdToDelete: string = action.streamId;
				this.forecastV3Service.deleteStream(streamIdToDelete).subscribe(() => {
					const state: ForecastV3StateModel = context.getState();
					const streams: Stream[] = state.streams;
					const i: number = streams.findIndex(stream => stream.streamId === streamIdToDelete);
					if (i > -1) {
						streams.splice(i, 1);
					}
					state.streams = streams;
					context.patchState(state);
					this.updateForecastsForDeletedStreams(context, streamIdToDelete, action.parentForecast).then(() => {
						resolve();
					});
				});
			} catch (error) {
				reject(new Error('Could not delete stream'));
			}
		});
	}

	@Action(DeleteForecast)
	deleteForecast(context: StateContext<ForecastV3StateModel>, action: DeleteForecast) {
		return new Promise<void>(async (resolve, reject) => {
			try {
				const forecastToDelete: string = action.forecastId;
				const state: ForecastV3StateModel = context.getState();
				const forecasts: IForecastV3Response[] = state.forecasts;
				const i: number = forecasts.findIndex(forecast => forecast.forecastId === forecastToDelete);
				if (i > -1) {
					forecasts.splice(i, 1);
				}
				state.forecasts = forecasts;
				context.patchState(state);
				await firstValueFrom(this.forecastV3Service.deleteForecast(forecastToDelete));
				this.updatedEventsService.updateItem(new ForecastV3UpdatedEvent(ActionType.delete, action.forecastId));
				this.updateForecastsForDeletedForecasts(context, action.forecastId);
				resolve();
			} catch (error) {
				reject(new Error('Could not delete forecast'));
			}
		});
	}

	@Action(RetryLazyLoadForecastData)
	retryLazyLoadForecastData(context: StateContext<ForecastV3StateModel>) {
		this.lazyLoadVisibleForecastData(context);
	}

	private addForecastsToForecastState(context: StateContext<ForecastV3StateModel>, forecasts: IForecastV3Response[]) {
		const state = context.getState();
		if (forecasts && state.forecasts) {
			const newReportsToAdd: IForecastV3Response[] = forecasts.filter(
				(filterReport: IForecastV3Response) => !state.forecasts.find((findReport: IForecastV3Response) => filterReport.forecastId === findReport.forecastId)
			);
			if (newReportsToAdd.length) {
				state.forecasts = state.forecasts.concat(newReportsToAdd);
				context.patchState(state);
			}
		} else if (forecasts && !state.forecasts) {
			state.forecasts = forecasts;
			context.patchState(state);
			this.lazyLoadVisibleForecastData(context);
		}
	}

	private addStreamsToForecastState(context: StateContext<ForecastV3StateModel>, streams: Stream[]) {
		const state = context.getState();
		if (streams && state.streams) {
			const newStreamsToAdd: Stream[] = streams.filter(
				(filterStream: Stream) => !state.streams.find((findStream: Stream) => filterStream.streamId === findStream.streamId)
			);
			if (newStreamsToAdd.length) {
				state.streams = state.streams.concat(newStreamsToAdd);
				context.patchState(state);
			}
		} else if (streams && !state.streams) {
			state.streams = streams;
			context.patchState(state);
		}
	}

	private async patchChangedStateForecasts(context: StateContext<ForecastV3StateModel>, forecasts: IForecastV3Response[]): Promise<void> {
		return new Promise(async (resolve, reject) => {
			try {
				const requests = [];
				const stateCopy: ForecastV3StateModel = JSON.parse(JSON.stringify(context.getState()));
				forecasts.forEach(forecast => {
					requests.push(this.forecastV3Service.getForecastData(forecast.forecastId, forecast.cadence, forecast.startDate, forecast.endDate).toPromise());
				});
				if (requests.length > 0) {
					await Promise.all(requests).then(results => {
						results.forEach(result => {
							const updatedForecast: IForecastV3Response = result?.body;
							const forecastIndexToUpdate: number = stateCopy.forecasts.findIndex(
								(filter: IForecastV3Response) => updatedForecast.forecastId === filter.forecastId
							);
							stateCopy.forecasts[forecastIndexToUpdate] = updatedForecast;
						});
						context.patchState({ forecasts: stateCopy.forecasts });
						stateCopy.forecasts.forEach((changedForecast: IForecastV3Response) => {
							this.updatedEventsService.updateItem(new ForecastV3UpdatedEvent(ActionType.update, changedForecast.forecastId, changedForecast));
						});
						resolve();
					});
				} else {
					resolve();
				}
			} catch (error) {
				reject(error);
			}
		});
	}

	private async updateForecastsForDeletedStreams(
		context: StateContext<ForecastV3StateModel>,
		streamId: string,
		parentForecast?: IForecastV3Response
	): Promise<void> {
		return new Promise<void>(async (resolve, reject) => {
			try {
				const state: ForecastV3StateModel = context.getState();
				if (parentForecast) {
					await this.patchChangedStateForecasts(context, [parentForecast]);
					resolve();
				}

				const affectedForecasts: IForecastV3Response[] = [];
				// Check Streams
				state.forecasts.forEach((forecast: IForecastV3Response) => {
					forecast.streams.forEach((stream: StreamsEntity) => {
						if (stream.streamId === streamId) {
							affectedForecasts.push(forecast);
						}
					});
				});

				// Check Stream Groups
				state.forecasts.forEach((forecast: IForecastV3Response) => {
					forecast.streamGroups.forEach((streamGroup: StreamGroupsEntity) => {
						streamGroup.streams.forEach((stream: StreamsEntity) => {
							if (stream.streamId === streamId) {
								affectedForecasts.push(forecast);
							}
						});
					});
				});
				// Removes duplicate forecasts from this array
				const uniqueAffectedForecasts: IForecastV3Response[] = [...new Map(affectedForecasts.map((f: IForecastV3Response) => [f.forecastId, f])).values()];

				if (parentForecast) {
					const i: number = uniqueAffectedForecasts.findIndex(forecast => forecast.forecastId === parentForecast.forecastId);
					if (i >= 0) {
						uniqueAffectedForecasts.splice(i, 1);
					}
				}

				this.patchChangedStateForecasts(context, uniqueAffectedForecasts);
				resolve();
			} catch (error) {
				reject(new Error('Could not update associated forecasts'));
			}
		});
	}

	private async updateForecastsForDeletedForecasts(context: StateContext<ForecastV3StateModel>, forecastId: string) {
		const state: ForecastV3StateModel = context.getState();
		const affectedForecasts: IForecastV3Response[] = [];
		// Check child Forecasts
		state.forecasts.forEach((forecast: IForecastV3Response) => {
			forecast.streams.forEach((stream: StreamsEntity) => {
				if (stream.forecastId === forecastId) {
					affectedForecasts.push(forecast);
				}
			});
		});
		const uniqueAffectedForecasts: IForecastV3Response[] = [...new Map(affectedForecasts.map((f: IForecastV3Response) => [f.forecastId, f])).values()];
		await this.patchChangedStateForecasts(context, uniqueAffectedForecasts);
	}

	@Action(ClearForecastV3State)
	clearForecastV3State(context: StateContext<ForecastV3StateModel>) {
		this.isInitialized = false;
		this.appReadySub.unsubscribe();
		const state: ForecastV3StateModel = context.getState();
		Object.keys(state).forEach((key: string) => {
			state[key] = null;
		});
		context.patchState(state);
	}

	private async lazyLoadVisibleForecastData(context: StateContext<ForecastV3StateModel>) {
		try {
			context.patchState({ preFetchInFlight: true });
			const visibleForecastIds: string[] = await this.preferencesFacadeService.getVisibleSnackIds(SnackType.forecastV3);
			const state = context.getState();
			const forecastsToLoad: IForecastV3Response[] = [];
			state.forecasts.forEach((forecast: IForecastV3Response) => {
				if (!forecast.cashBalances && visibleForecastIds.includes(forecast.forecastId)) {
					forecastsToLoad.push(forecast);
				}
			});
			// by catching errors in promise all and returning this allows it to wait for all requests to either error out or complete succesfully
			await Promise.all(forecastsToLoad.map((forecast: IForecastV3Response) => this.lazyLoadForecastsData(context, forecast).catch(error => new Error(error))));
			context.patchState({ preFetchInFlight: false });
		} catch (error) {
			context.patchState({ preFetchInFlight: false });
		}
	}

	private lazyLoadForecastsData(context: StateContext<ForecastV3StateModel>, forecast: IForecastV3Response): Promise<void> {
		return new Promise((resolve, reject) => {
			const state: ForecastV3StateModel = context.getState();
			try {
				let startDate: string;
				let endDate: string;
				if (!forecast.isRolling) {
					startDate = forecast.startDate;
					endDate = forecast.endDate;
				} else {
					const currentDate: DateTime = DateTime.now();
					let lStartDate: DateTime;
					if (forecast.rollingOffset < 0) {
						lStartDate = currentDate.minus({ days: Math.abs(forecast.rollingOffset) });
					} else {
						lStartDate = currentDate.plus({ days: forecast.rollingOffset });
					}
					const lEndDate: DateTime = lStartDate.plus({ days: forecast.rollingPeriods - 1 });
					startDate = lStartDate.toFormat('yyyy-MM-dd');
					endDate = lEndDate.toFormat('yyyy-MM-dd');
				}

				this.forecastV3Service.getForecastData(forecast.forecastId, forecast.cadence, startDate, endDate).subscribe({
					next: (response: HttpResponse<IForecastV3Response>) => {
						const fullForecast: IForecastV3Response = response.body;
						const forecastIndex: number = state.forecasts.findIndex((filter: IForecastV3Response) => forecast.forecastId === filter.forecastId);
						state.forecasts[forecastIndex] = fullForecast;
						context.patchState({ forecasts: state.forecasts });
						resolve();
					},
					error: (error: HttpErrorResponse) => {
						forecast.errorMessage = error.message;
						reject(error);
					},
				});
			} catch (error) {
				forecast.errorMessage = error;
				reject(error);
			}
		});
	}

	private lazyLoadStreamsData(context: StateContext<ForecastV3StateModel>, stream: Stream): Promise<void> {
		return new Promise((resolve, reject) => {
			try {
				const state: ForecastV3StateModel = context.getState();
				let startDate: string;
				let endDate: string;
				if (!stream.isRolling) {
					startDate = stream.startDate;
					endDate = stream.endDate;
				} else {
					const currentDate: DateTime = DateTime.now();
					let lStartDate: DateTime;
					if (stream.rollingOffset < 0) {
						lStartDate = currentDate.minus({ days: Math.abs(stream.rollingOffset) });
					} else {
						lStartDate = currentDate.plus({ days: stream.rollingOffset });
					}
					const lEndDate: DateTime = lStartDate.plus({ days: stream.rollingPeriods - 1 });
					startDate = lStartDate.toFormat('yyyy-MM-dd');
					endDate = lEndDate.toFormat('yyyy-MM-dd');
				}
				this.forecastV3Service.getStreamData(stream.streamId, startDate, endDate, null, stream.currency).subscribe({
					next: (response: HttpResponse<Stream>) => {
						const fullStream: Stream = response.body;
						const streamIndex: number = state.streams.findIndex((filter: Stream) => stream.streamId === filter.streamId);
						state.streams[streamIndex] = fullStream;
						context.patchState({ streams: state.streams });
						resolve();
					},
					error: (error: HttpErrorResponse) => {
						stream.errorMessage = error.message;
					},
				});
			} catch (error) {
				stream.errorMessage = error;
				reject(error);
			}
		});
	}

	@Action(AddStreamDataToState)
	addStreamDataToState(context: StateContext<ForecastV3StateModel>, action: AddStreamDataToState) {
		const state: ForecastV3StateModel = context.getState();
		const streamsCopy: Stream[] = state.streams;
		const fullStream: Stream = action.stream;
		const i = streamsCopy.findIndex(stream => stream.streamId === fullStream.streamId);
		if (i >= 0) {
			streamsCopy[i] = fullStream;
		} else {
			streamsCopy.push(fullStream);
		}
		state.streams = streamsCopy;
		context.patchState(state);
	}

	@Action(AddForecastDataToState)
	addForecastDataToState(context: StateContext<ForecastV3StateModel>, action: AddForecastDataToState) {
		const state: ForecastV3StateModel = context.getState();
		const forecastsCopy: IForecastV3Response[] = state.forecasts;
		const fullForecast: IForecastV3Response = action.forecast;
		const i = forecastsCopy.findIndex(forecast => forecast.forecastId === fullForecast.forecastId);
		if (i >= 0) {
			forecastsCopy[i] = fullForecast;
		} else {
			forecastsCopy.push(fullForecast);
		}
		const sortedForecastsCopy = this.sortByName(forecastsCopy);
		context.patchState({ forecasts: sortedForecastsCopy });
	}

	private locallyUpdateStreamData(context: StateContext<ForecastV3StateModel>, streamId: string, streamValues: StreamValue[]) {
		context.getState();
		const state: ForecastV3StateModel = context.getState();
		const streamsCopy: Stream[] = state.streams;
		const streamToUpdateIndex: number = streamsCopy.findIndex(stream => stream.streamId === streamId);
		streamValues.forEach(value => {
			const valueToUpdateIndex: number = streamsCopy[streamToUpdateIndex].values?.findIndex(ogValue => ogValue.date === value.date);
			if (valueToUpdateIndex >= 0) {
				streamsCopy[streamToUpdateIndex].values[valueToUpdateIndex] = {
					actual: value.actual || value.actual === 0 ? value.actual : streamsCopy[streamToUpdateIndex].values[valueToUpdateIndex].actual,
					actualManuallyEdited: value.actualManuallyEdited
						? value.actualManuallyEdited
						: streamsCopy[streamToUpdateIndex].values[valueToUpdateIndex].actualManuallyEdited,
					forecasted: value.forecasted || value.forecasted === 0 ? value.forecasted : streamsCopy[streamToUpdateIndex].values[valueToUpdateIndex].forecasted,
					forecastedManuallyEdited: value.forecastedManuallyEdited
						? value.forecastedManuallyEdited
						: streamsCopy[streamToUpdateIndex].values[valueToUpdateIndex].forecastedManuallyEdited,
					date: value.date,
				};
			}
		});
		context.patchState({ streams: streamsCopy });
	}

	private forecastStateIsCached(deserializedState: TrovataAppState): boolean {
		const deserializedForecastState: ForecastV3StateModel | undefined = deserializedState.forecastV3;
		if (deserializedForecastState && deserializedForecastState.forecasts) {
			return true;
		} else {
			return false;
		}
	}

	private streamStateIsCached(deserializedState: TrovataAppState): boolean {
		const deserializedForecastState: ForecastV3StateModel | undefined = deserializedState.forecastV3;
		if (deserializedForecastState && deserializedForecastState.streams) {
			return true;
		} else {
			return false;
		}
	}

	private sortByName(forecasts: IForecastV3Response[]): IForecastV3Response[] {
		forecasts = forecasts.sort((forecastA: IForecastV3Response, forecastB: IForecastV3Response) => {
			if (forecastA.name.toLowerCase() < forecastB.name.toLowerCase()) {
				return -1;
			} else if (forecastA.name.toLowerCase() > forecastB.name.toLowerCase()) {
				return 1;
			}
			return 0;
		});
		return forecasts;
	}
}
